import STUtils from "../../assets/styles/utility.module.scss";
import STMemberEditForm from "../../assets/styles/organisms/memberEditForm.module.scss";
import { MemberEditForm as TMemberEditForm } from "../../types/form/memberEditForm";
import { useEffect, useState } from "react";
import Button from "../atoms/Button";
import STButton from "../../assets/styles/atoms/button.module.scss";
import PAGE_CONFIG from "../../config/page";
import TApiResult from "../../types/api/TApiResult";
import AlartMessageBox from "../atoms/AlartMessageBox";
import {
  fetchMemberDetail,
  TApiMemberDetailResult,
} from "../../utils/api/fetchMemberDetail";
import {
  postMemberUpdate,
  TApiMemberUpdateValidateErrors,
} from "../../utils/api/postMemberUpdate";
import SingleTextRow from "../molecules/formTableRow/SingleTextRow";
import {
  MEMBER_EDIT_FORM_ITEM,
  TAppMemberEditFormItem,
} from "../../types/TAppMemberEditFormItem";
import { TAppFormError } from "../../types/TAppFormError";
import SinglePasswordRow from "../molecules/formTableRow/SinglePasswordRow";
import { useHistory, useLocation } from "react-router-dom";
import SingleTextWithIconRow from "../molecules/formTableRow/SingleTextWithIconRow";
import SingleCheckBoxRow from "../molecules/formTableRow/SingleCheckBoxRow";
import MultiPullDownRow from "../molecules/formTableRow/MultiPullDownRow";
import { routes } from "../../router/Router";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import { years, months, days } from "../atoms/SelectDate";
import filterMessageFormError from "../../utils/form/filterMessageFormError";
import { Helmet } from "react-helmet";
import CONSTANTS from "../../utils/constants";
import SingleCheckBox from "../molecules/formTableRow/SingleCheckBox";
import TableRow from "../atoms/TableRow";

type TmpPassword = {
  existPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const MemberEditForm: React.FC = () => {
  // const defaultYear = new Date().getFullYear() - 30; // 30年前を初期値
  // const defaultDate = new Date(defaultYear, 0, 1);

  const initial: TMemberEditForm = {
    tel: "",
    email: "",
    birthday: undefined,
    instagramOwnerinput: "",
    line: 0,
    existPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
    mail_not_recommended: 0,
    line_not_recommended: 0,
  };
  const [formErrors, setFormErrors] = useState<
    TAppFormError<TAppMemberEditFormItem>[]
  >([]);

  const [tmpPassword, setTmpPassword] = useState<TmpPassword>({
    existPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });

  const [form, setForm] = useState(initial);
  const [passwordFormOpen, setPasswordFormOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [name, setName] = useState("");
  const [nameKana, setNameKana] = useState("");
  const [canApply, setCanApply] = useState(false);
  const history = useHistory();
  const [isShownUnlinkLine, setIsShownUnlinkLine] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    fetchMemberDetail().then((res: TApiResult & TApiMemberDetailResult) => {
      if (!res.isSuccess || !res.result) return history.push(routes.error.path);
      const newForm: TMemberEditForm = {
        ...initial,
        ...{
          tel: res.result.tel,
          email: res.result.email,
          birthday:
            res.result.birthday !== null
              ? new Date(res.result.birthday)
              : undefined,
          instagramOwnerinput: res.result.instagram_ownerinput,
          line: 0,
          mail_not_recommended: res.result.mail_not_recommended,
          line_not_recommended: res.result.line_not_recommended,
        },
      };

      setIsShownUnlinkLine(res.result.link_user_line_account === 1);
      setForm(newForm);
      setName(`${res.result.sei}${res.result.mei}`);
      setNameKana(`${res.result.kana_sei}${res.result.kana_mei}`);
    });

    if (
      form.existPassword !== "" ||
      form.newPassword !== "" ||
      form.newPasswordConfirm !== ""
    )
      setPasswordFormOpen(true);
  }, []);

  useEffect(() => {
    if (!isChange) return;

    const newErrors = filterMessageFormError(
      "new_password_confirm" as TAppMemberEditFormItem,
      "パスワードが一致しません",
      formErrors
    );

    /*
    if (
      form.newPassword !== "" &&
      !PAGE_CONFIG.PASSWORD_REGEX.test(form.newPassword)
    ) {
      newErrors.push({
        key: "new_password" as TAppMemberEditFormItem,
        messages: ["8文字以上の半角英数字で入力してください"],
      });
    }
    */

    if (form.newPassword !== form.newPasswordConfirm) {
      newErrors.push({
        key: "new_password_confirm" as TAppMemberEditFormItem,
        messages: ["パスワードが一致しません"],
      });
    }
    setFormErrors(newErrors);
    setCanApply(
      validForm(form, passwordFormOpen, true) &&
        newErrors.filter((e) => e.messages.length !== 0).length === 0
    );
  }, [form]);

  const hundleChange = (newForm: TMemberEditForm): void => {
    setIsChange(true);
    setForm(newForm);
  };

  const isPasswordFormEmpty = (newForm: TMemberEditForm) => {
    return (
      newForm.existPassword === "" &&
      newForm.newPassword === "" &&
      newForm.newPasswordConfirm === ""
    );
  };

  const validForm = (
    newForm: TMemberEditForm,
    newPasswordFormOpen: boolean,
    newIsChange: boolean
  ): boolean => {
    const isValidMemberForm = newForm.email !== "" && newForm.tel !== "";
    const isValidPasswordForm =
      (!newPasswordFormOpen && isPasswordFormEmpty(newForm)) ||
      (newPasswordFormOpen &&
        newForm.existPassword !== "" &&
        newForm.newPassword !== "" &&
        newForm.newPassword === newForm.newPasswordConfirm &&
        CONSTANTS.PATTERN.PASSWORD.test(newForm.newPassword));
    return newIsChange && isValidMemberForm && isValidPasswordForm;
  };

  const hundleSubmit = (): void => {
    window.scrollTo(0, 0);
    if (!validForm(form, passwordFormOpen, isChange)) return;
    const { newPasswordConfirm, ...formParam } = form;
    const params = { ...formParam };

    postMemberUpdate(params).then(
      (res: TApiResult & TApiMemberUpdateValidateErrors) => {
        if (!res.isSuccess) {
          setFormErrors(res.validateErrors);
        } else {
          setIsUpdated(true);
          postMemberAccessLog(ACCESS_LOG_ACTION.memberEdit, document.referrer);
        }
      }
    );
  };

  const hundleClick = (): void => {
    if (passwordFormOpen) {
      setTmpPassword({
        existPassword: form.existPassword,
        newPassword: form.newPassword,
        newPasswordConfirm: form.newPasswordConfirm,
      });
      const newForm = {
        ...form,
        ...{
          existPassword: "",
          newPassword: "",
          newPasswordConfirm: "",
        },
      };
      setForm(newForm);
      setCanApply(validForm(newForm, !passwordFormOpen, isChange));
    } else {
      const newForm = {
        ...form,
        ...tmpPassword,
      };
      setForm(newForm);
      setCanApply(validForm(newForm, !passwordFormOpen, isChange));
    }
    setPasswordFormOpen(!passwordFormOpen);
  };

  return (
    <div
      className={`${STMemberEditForm.member_edit_form} ${STUtils.container_form}`}
    >
      <Helmet>
        <meta http-equiv="cache-control" content="no-cache" />
      </Helmet>
      <h1 className={STMemberEditForm.member_edit_form_title}>会員情報変更</h1>
      <div className={STMemberEditForm.member_edit_form_form}>
        {isUpdated && <AlartMessageBox message="会員情報が更新されました。" />}

        <table className={STMemberEditForm.member_edit_form_table}>
          <tr>
            <th>名前</th>
            <td className={STMemberEditForm.member_edit_form_text}>{name}</td>
          </tr>
          <tr>
            <th>ふりがな</th>
            <td className={STMemberEditForm.member_edit_form_text}>
              {nameKana}
            </td>
          </tr>
          <SingleTextRow<TAppMemberEditFormItem>
            defaultValue={form.tel}
            title="電話番号"
            formKey={MEMBER_EDIT_FORM_ITEM.tel}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) => hundleChange({ ...form, tel: input })}
            pattern={CONSTANTS.PATTERN.ONLY_NUMBER}
            patternText="半角数字"
            maxLength={11}
            minLength={10}
            required={true}
          />
          <SingleTextRow<TAppMemberEditFormItem>
            defaultValue={form.email}
            title="メールアドレス"
            formKey={MEMBER_EDIT_FORM_ITEM.email}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) => hundleChange({ ...form, email: input })}
            maxLength={255}
            minLength={3}
            pattern={CONSTANTS.PATTERN.EMAIL}
            patternText="メールアドレスの形式"
            required={true}
          />
          <MultiPullDownRow<TAppMemberEditFormItem>
            defaultValue={form.birthday}
            title="お誕生日"
            formKey={MEMBER_EDIT_FORM_ITEM.birthday}
            errors={formErrors}
            setErrors={setFormErrors}
            hundleOnChange={(input) =>
              hundleChange({ ...form, birthday: input })
            }
            options={{ years: years, months: months, days: days }}
          />
          <SingleTextWithIconRow<TAppMemberEditFormItem>
            defaultValue={form.instagramOwnerinput}
            title="Instagram"
            formKey={MEMBER_EDIT_FORM_ITEM.instagramOwnerinput}
            errors={formErrors}
            setErrors={setFormErrors}
            pattern={CONSTANTS.PATTERN.INSTAGRAM}
            patternText="記号は「. _」のみ"
            maxLength={30}
            autoComplete="new-password"
            hundleOnChange={(input) =>
              hundleChange({ ...form, instagramOwnerinput: input })
            }
          />
          {isShownUnlinkLine && (
            <SingleCheckBoxRow<TAppMemberEditFormItem>
              defaultValue={form.line}
              title="LINE連携"
              label="LINE連携を解除する"
              formKey={MEMBER_EDIT_FORM_ITEM.line}
              errors={formErrors}
              setErrors={setFormErrors}
              hundleOnChange={(input: number) =>
                hundleChange({ ...form, line: input })
              }
            />
          )}
          <TableRow title="お知らせ">
            <div>
              <SingleCheckBox<TAppMemberEditFormItem>
                defaultValue={form.mail_not_recommended}
                label="メール送信案内を希望しない"
                formKey={MEMBER_EDIT_FORM_ITEM.mail_not_recommended}
                errors={formErrors}
                setErrors={setFormErrors}
                hundleOnChange={(input: number) =>
                  hundleChange({ ...form, mail_not_recommended: input })
                }
              />
            </div>
            {isShownUnlinkLine && (
              <div style={{ marginTop: "10px" }}>
                <SingleCheckBox<TAppMemberEditFormItem>
                  defaultValue={form.line_not_recommended}
                  label="LINE送信案内を希望しない"
                  formKey={MEMBER_EDIT_FORM_ITEM.line_not_recommended}
                  errors={formErrors}
                  setErrors={setFormErrors}
                  hundleOnChange={(input: number) =>
                    hundleChange({ ...form, line_not_recommended: input })
                  }
                />
              </div>
            )}
          </TableRow>
        </table>
        <div
          className={
            passwordFormOpen
              ? `${STMemberEditForm.member_edit_form_change_password_btn_open}`
              : `${STMemberEditForm.member_edit_form_change_password_btn}`
          }
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            hundleClick();
          }}
        >
          パスワードを変更する
        </div>
      </div>
      <table
        className={`${passwordFormOpen ? "" : STUtils.dn} ${
          STMemberEditForm.member_edit_form_table
        }`}
      >
        <SinglePasswordRow<TAppMemberEditFormItem>
          defaultValue={form.existPassword}
          title="現在のパスワード"
          formKey={MEMBER_EDIT_FORM_ITEM.existPassword}
          errors={formErrors}
          setErrors={setFormErrors}
          hundleOnChange={(input) =>
            hundleChange({ ...form, existPassword: input })
          }
          maxLength={20}
          placeholder="現在のパスワード"
          minLength={8}
          autoComplete="new-password"
          pattern={CONSTANTS.PATTERN.PASSWORD}
          patternText="英数字記号"
        />
        <SinglePasswordRow<TAppMemberEditFormItem>
          defaultValue={form.newPassword}
          title="変更するパスワード"
          placeholder="パスワード8桁以上の英数字"
          formKey={MEMBER_EDIT_FORM_ITEM.newPassword}
          errors={formErrors}
          setErrors={setFormErrors}
          hundleOnChange={(input) =>
            hundleChange({ ...form, newPassword: input })
          }
          maxLength={20}
          minLength={8}
          autoComplete="new-password"
          pattern={CONSTANTS.PATTERN.PASSWORD}
          patternText="英数字記号"
        />
        <SinglePasswordRow<TAppMemberEditFormItem>
          defaultValue={form.newPasswordConfirm}
          title="変更するパスワード"
          subTitle="（確認）"
          placeholder="パスワード再入力"
          formKey={MEMBER_EDIT_FORM_ITEM.newPasswordConfirm}
          errors={formErrors}
          setErrors={setFormErrors}
          hundleOnChange={(input) =>
            hundleChange({ ...form, newPasswordConfirm: input })
          }
          maxLength={20}
          minLength={8}
          autoComplete="new-password"
        />
      </table>
      <Button
        className={
          canApply
            ? `${STMemberEditForm.apply_btn} ${STButton.primary}`
            : `${STMemberEditForm.apply_btn} ${STButton.disable}`
        }
        clickAction={hundleSubmit}
        text="変更"
        disabled={!canApply}
      />
    </div>
  );
};

export default MemberEditForm;
