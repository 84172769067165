import { StylesProvider } from '@material-ui/core/styles';
import STCustomAccordion from "../../assets/styles/molecules/customAccordion.module.scss";
import STFile from "../../assets/styles/organisms/file.module.scss";
import { getSubdomain } from "../../utils/getSubdomain";
import { routes } from "../../router/Router";
import { useHistory } from "react-router-dom";
import BaseHead from "../templates/BaseHead";
import { Box, Card, Typography } from "@material-ui/core";
import { FileDetails } from "../organisms/FileDetails";
import { Accordion, AccordionSummary } from "@material-ui/core";
import { FolderOpen, ExpandMore } from "@material-ui/icons";
import { useEffect, useState } from "react";
import axios from "axios";
import { getAuth } from "firebase/auth";
import tokenNotFound from "../../utils/api/config/tokenNotFound";
import TopBackground from "../organisms/TopBackground";
import { postMemberAccessLog } from "../../utils/api/postMemberAccessLog";
import { ACCESS_LOG_ACTION } from "../../types/TAppAccessLogAction";
import BottomNavigationBar from "../organisms/BottomNavigationBar";
import { FileUploadResponse } from "../../types/FileUploadResponse";
import { FileDownloadResponse } from "../../types/FileDownloadResponse";
import { FolderAndFileProps } from "../../types/FolderAndFileProps";

const FolderAndFileList = ({
  folderName,
  files,
  folderId,
}: FolderAndFileProps) => {
  const isNoFiles = folderName === "共有されているファイルはありません。";
  const showAccordion = folderName === "その他のファイル";
  const history = useHistory();
  const handleFolderClick = () => {
    if (folderId) {
      // フォルダ詳細ページへ遷移
      history.push(`/files/${folderId}`);
    }
  };

  const getDownload = async (fileId: number) => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (!token) return tokenNotFound;
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/uploads/download`;
      const { data } = await axios.post<FileDownloadResponse>(
        url,
        {
          uploads_file_id: fileId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const link = document.createElement("a");
      link.href = data.data.url;
      link.click();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      {showAccordion ? (
        <Accordion 
          defaultExpanded
          className={STCustomAccordion.custom_accordion}
          >
          <AccordionSummary
            expandIcon={
              !isNoFiles ? <ExpandMore style={{ color: "#0E0E0E" }} /> : null
            }
            className={STCustomAccordion.custom_accordion_summary}
          >
            <Box display="flex" gridGap={8} alignItems="center">
              {!isNoFiles && <FolderOpen style={{ color: "#0E0E0E" }} />}
              <Typography className={STFile.folder_name}>
                {"その他のファイル"}
              </Typography>
            </Box>
          </AccordionSummary>
          {files.length === 0 ? (
            <Typography>　共有されているファイルはありません。</Typography>
          ) : (
            files
              .sort((a, b) => (a.modified > b.modified ? -1 : 1))
              .map((file) => (
                <FileDetails
                  key={file.id}
                  file={file}
                  getDownload={getDownload}
                />
              ))
          )}
        </Accordion>
      ) : (
        <Accordion className={STCustomAccordion.custom_accordion}>
          <AccordionSummary className={STCustomAccordion.custom_accordion_summary}>
            <Box
              display="flex"
              gridGap={8}
              alignItems="center"
              onClick={handleFolderClick}
            >
              {!isNoFiles && <FolderOpen style={{ color: "#0E0E0E" }} />}
              <Typography className={STFile.folder_name}>
                {folderName || "その他のファイル"}
              </Typography>
            </Box>
          </AccordionSummary>
        </Accordion>
      )}
    </>
  );
};

export const FilesPage: React.FC = () => {
  const subdomain = getSubdomain(window.location.hostname);
  const [uploadsResponse, setUploadsResponse] = useState<FileUploadResponse>();

  const getUploads = async () => {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();
    if (!token) return tokenNotFound;
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/uploads/list`;
      const { data } = await axios.get<FileUploadResponse>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUploadsResponse(data);
      postMemberAccessLog(ACCESS_LOG_ACTION.mypageFiles, document.referrer);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUploads();
  }, []);

  return (
    <StylesProvider injectFirst>
      <BaseHead
        title={routes.mypage.title}
        login
        subdomain={subdomain}
        hasBackground
      >
        <TopBackground />
        <Box display="flex" justifyContent="center" mt={4} mx={2}>
          <Card className={STFile.card}>
            <Typography className={STFile.card_header}>
              ファイルページTOP
            </Typography>
            {uploadsResponse && (
              <>
                {uploadsResponse.data.folders.length === 0 &&
                uploadsResponse.data.root_files.length === 0 ? (
                  <FolderAndFileList
                    folderName="共有されているファイルはありません。"
                    files={[]}
                  />
                ) : (
                  <>
                    {uploadsResponse.data.folders
                      .sort((a, b) => (a.modified > b.modified ? -1 : 1))
                      .map((folder) => (
                        <FolderAndFileList
                          key={folder.id}
                          folderName={folder.name}
                          files={folder.files}
                          folderId={folder.id}
                        />
                      ))}
                    {uploadsResponse.data.root_files.length > 0 && (
                      <FolderAndFileList
                        files={uploadsResponse.data.root_files}
                        folderName="その他のファイル"
                      />
                    )}
                  </>
                )}
              </>
            )}
          </Card>
        </Box>
        <BottomNavigationBar />
      </BaseHead>
    </StylesProvider>
  );
};
